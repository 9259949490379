import axios from 'axios'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import { usePostHog } from 'posthog-js/react'
import { FC, useState } from 'react'
import { Store } from 'react-notifications-component'
import { fromString } from 'uuidv4'
import * as Yup from 'yup'

import Breadcrumbs from '../../components/Breadcrumbs'
import Container from '../../components/Container'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import { TitleStyled } from '../../components/common/Title'
import { useQueryParam } from '../../helpers'
import illustration from '../../images/contact.svg'
import { useIntlLabel } from '../../intl'
import {
    ContactInfosWrapperStyled,
    ContainerInnerFormStyled,
    ContainerInnerInfosStyled,
    ContainerInnerStyled,
    InputErrorStyled,
    InputStyled,
    SubmitStyled,
    TextAreaStyled,
} from './_styles'
import { ContactPageProps, Shape, Values } from './_types'
import MySelect from './components/MySelect'

const ContactPage: FC<ContactPageProps> = ({ data }) => {
    const intl = useIntl()
    const getIntlLabel = useIntlLabel()
    const subject = useQueryParam('subject')
    const posthog = usePostHog()

    const subjectOptions = data.allStrapiServiceCategory.nodes.map((node) => {
        return {
            value: getIntlLabel(node, 'categoryName'),
            label: getIntlLabel(node, 'categoryName'),
        }
    })

    subjectOptions.unshift({
        value: intl.formatMessage({ id: 'contact_other_category' }),
        label: intl.formatMessage({ id: 'contact_other_category' }),
    })

    const regionOptions = data.allRegions.nodes[1].data?.map((region) => {
        const firstLetter = region?.name?.substring(0, 1)
        const rest = (region?.name?.substring(1, region.name.length) ?? '').toLowerCase()
        const regionName = firstLetter?.toUpperCase() + rest

        return {
            value: regionName,
            label: regionName,
        }
    })

    const initValues: Values = {
        fullName: '',
        email: '',
        subject: subjectOptions[0].label ?? '',
        message: '',
        region: '',
    }

    const encode = (data: any) => {
        return Object.keys(data)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&')
    }

    const [serverState, setServerState] = useState({ status: false, message: '' })
    const handleServerResponse = (status: boolean, message: string) => {
        setServerState({ status, message })

        Store.addNotification({
            title: 'Envoi réussi',
            message: message,
            type: 'success',
            insert: 'bottom',
            container: 'bottom-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        })
    }

    const onSubmit = (values: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
        /*posthog?.identify(fromString(values.email), {
            email: values.email,
            name: values.fullName,
            location: values.region,
        })*/
        axios({
            method: 'POST',
            url: 'https://formspree.io/f/myyabvqz',
            data: values,
        })
            .then(() => {
                posthog?.capture('request_sent', {
                    subject: values.subject,
                    location: values.region,
                })
                setSubmitting(false)
                resetForm()
                handleServerResponse(
                    true,
                    intl.formatMessage({ id: 'contact_thank_for_the_request' }),
                )
            })
            .catch((error) => {
                console.log(error)
                handleServerResponse(false, error.response.data.error)
            })
            .finally(() => setSubmitting(false))
    }

    const requestFormSchema = Yup.object<Shape<Values>>({
        email: Yup.string()
            .email()
            .required(intl.formatMessage({ id: 'contact_required_email' })),
        fullName: Yup.string(),
        message: Yup.string().required(intl.formatMessage({ id: 'contact_required_message' })),
        region: Yup.string(),
        subject: Yup.string(),
    })

    return (
        <Layout>
            <SEO
                title={intl.formatMessage({ id: 'site_contact_title' })}
                description={getIntlLabel(data.strapiRequestSection, 'description')}
                pageUrl="/contact"
            />
            <Breadcrumbs current={intl.formatMessage({ id: 'site_contact_title' })} />
            <Container>
                <ContainerInnerStyled>
                    <ContainerInnerFormStyled>
                        <h2 style={{ marginTop: 0, marginBottom: 32 }}>{intl.formatMessage({ id: 'contact_us' })}</h2>
                        <Formik
                            initialValues={initValues}
                            onSubmit={onSubmit}
                            validationSchema={requestFormSchema}
                        >
                            {({ errors, touched, isSubmitting }) => (
                                <Form>
                                    <label htmlFor="fullname">
                                        {intl.formatMessage({ id: 'contact_fullname' })}
                                    </label>
                                    <Field
                                        id="fullName"
                                        name="fullName"
                                        placeholder={intl.formatMessage({ id: 'contact_fullname' })}
                                        as={InputStyled}
                                    />
                                    <InputErrorStyled>
                                        {errors.fullName && touched.fullName
                                            ? errors.fullName
                                            : null}
                                    </InputErrorStyled>

                                    <label htmlFor="region">
                                        {intl.formatMessage({ id: 'contact_region' })}
                                    </label>
                                    <Field
                                        id="region"
                                        name="region"
                                        placeholder={intl.formatMessage({ id: 'contact_region' })}
                                        component={MySelect}
                                        //defaultValue={regionOptions[2]}
                                        options={regionOptions}
                                    />
                                    <InputErrorStyled>
                                        {errors.region && touched.region ? errors.region : null}
                                    </InputErrorStyled>

                                    <label htmlFor="subject">
                                        {intl.formatMessage({ id: 'contact_theme' })}
                                    </label>
                                    <Field
                                        id="subject"
                                        name="subject"
                                        placeholder={intl.formatMessage({ id: 'contact_theme' })}
                                        component={MySelect}
                                        //defaultValue={subjectOptions[0]}
                                        options={subjectOptions}
                                    />
                                    <InputErrorStyled>
                                        {errors.subject && touched.subject ? errors.subject : null}
                                    </InputErrorStyled>

                                    <label htmlFor="email">
                                        {intl.formatMessage({ id: 'contact_email' })} (
                                        {intl.formatMessage({ id: 'contact_mandatory' })})
                                    </label>
                                    <Field
                                        id="email"
                                        name="email"
                                        placeholder={intl.formatMessage({ id: 'contact_email' })}
                                        as={InputStyled}
                                        type="email"
                                        hasError={errors.email && touched.email}
                                    />
                                    <InputErrorStyled>
                                        {errors.email && touched.email ? errors.email : null}
                                    </InputErrorStyled>

                                    <label htmlFor="message">
                                        {intl.formatMessage({ id: 'contact_message' })} (
                                        {intl.formatMessage({ id: 'contact_mandatory' })})
                                    </label>
                                    <Field
                                        id="message"
                                        name="message"
                                        placeholder={intl.formatMessage({ id: 'contact_message' })}
                                        as={TextAreaStyled}
                                        line="6"
                                        hasError={errors.email && touched.email}
                                    />
                                    <Field type="hidden" id="_gotcha" name="_gotcha" />
                                    <InputErrorStyled>
                                        {errors.message && touched.message ? errors.message : null}
                                    </InputErrorStyled>

                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <SubmitStyled type="submit" disabled={isSubmitting}>
                                            {isSubmitting
                                                ? intl.formatMessage({
                                                      id: 'contact_button_loading',
                                                  })
                                                : intl.formatMessage({ id: 'contact_button_text' })}
                                        </SubmitStyled>
                                    </div>

                                    {serverState && (
                                        <p className={!serverState.status ? 'errorMsg' : ''}>
                                            {serverState.message}
                                        </p>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </ContainerInnerFormStyled>
                    <ContainerInnerInfosStyled>
                        <ContactInfosWrapperStyled>
                            <img src={illustration} alt="" />
                            <div style={{ width: '100%' }}>
                                <h3>{intl.formatMessage({ id: 'contact_infos_title' })}</h3>
                            </div>

                            <p>{intl.formatMessage({ id: 'contact_infos_description' })}</p>
                        </ContactInfosWrapperStyled>
                    </ContainerInnerInfosStyled>
                </ContainerInnerStyled>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query ContactPageQuery {
        strapiRequestSection {
            description
            description_mg
        }
        allStrapiServiceCategory {
            nodes {
                id
                categoryName
                categoryName_mg
                services {
                    serviceName
                    serviceName_mg
                }
            }
        }
        allRegions {
            nodes {
                data {
                    name
                }
            }
        }
    }
`

export default ContactPage
